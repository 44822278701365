@font-face {
  font-family: "Cereal";
  src: url("../Assets/AirbnbCereal_W_Lt.otf");
  src: url("../Assets/AirbnbCereal_W_Lt.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

/* @font-face {
    font-family: 'Cereal';
    src: url('./AirbnbCereal_W_Bd.otf');
    src: url('./AirbnbCereal_W_Bd.otf') format('opentype');
    font-weight: bold;
} */

@font-face {
  font-family: "Cereal";
  src: url("../Assets/AirbnbCereal_W_Md.otf");
  src: url("../Assets/AirbnbCereal_W_Md.otf") format("opentype");
  font-weight: bold;
}

@font-face {
  font-family: "Cereal Header";
  src: url("../Assets/AirbnbCereal_W_Md.otf");
  src: url("../Assets/AirbnbCereal_W_Md.otf") format("opentype");
  font-weight: lighter;
}

body,
html {
  margin: 0;
  padding: 0;
  font-family: "Cereal", sans-serif, Arial, Helvetica;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Cereal Header";
}
.cereal-header {
  font-family: "Cereal Header";
}

a,
a:hover,
a:visited,
a:active,
a:focus {
  font-family: "Cereal Header";
  color: black;
  text-decoration: underline;
}

.abnb-container {
  width: 100%;
  height: 100%;
  margin-bottom: 25px;
  z-index: 0;
}

.abnb-list-container {
  width: 1300px;
  height: 100%;
  margin: 0 auto;
  margin-bottom: 25px;
  padding-bottom: 50px;
  z-index: 0;
}

.logo a,
.logo a:visited {
  color: #ff385c;
  text-decoration: none;
}

.abnb-1200 {
  width: 1300px;
}

.right a,
.right a:visited {
  text-decoration: none;
}

.abnb-navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-bottom: 1.5px solid #efefef;
}

.abnb-navbar-full {
  width: 100%;
}

.abnb-navbar div {
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  padding: 5px;
}

.abnb-navbar h1 {
  color: #ff385c;
  font-size: 16pt;
}
.abnb-search {
  width: 100%;
  height: 100%;
}

.abnb-search-input {
  width: 300px;
  border-radius: 40px;
  border: 1.5px solid #cacaca;
  box-shadow: 2px 2px 5px #efefef;
}

.abnb-search input,
.abnb-search input:focus {
  width: 100%;
  background-color: white;
  border: none;
  outline: none;
  font-size: 10pt;
}

.abnb-search span {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: #ff385c;
  border-radius: 50%;
  padding: 12px;
  margin-left: 15px;
  cursor: pointer;
}

.abnb-navbar .right {
  display: block;
  width: max-content;
  color: #3a3a3a;
  border: 1.5px solid #cacaca;
  padding: 10px 15px;
  border-radius: 60px;
  font-size: 14pt;
}

.abnb-navbar .right i {
  cursor: pointer;
}

.abnb-icons {
  width: 100%;
  text-align: center;
  padding: 40px;
}

.abnb-icons img {
  margin: 0 50px;
  width: 25px;
}
.abnb-main-cont {
  width: 100%;
}

.main-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  list-style: none;
  text-align: center;
}

.abnb-card {
  width: 300px;
  margin-right: 15px;
  text-align: left;
}

/* .main-list div { margin: 15px } */
/* .main-list img { border-radius: 15px; width: 300px; height: 265px; } */

.abnb-list-main-cont {
  padding-left: 50px;
  padding-top: 25px;
}
.listing-title {
  font-family: "Cereal Header";
  font-size: 18pt;
  margin-bottom: 5px;
}

.list-photos {
  width: 100%;
  height: 446px;
  display: flex;
  margin: 25px 0;
  border-radius: 15px;
  overflow: hidden;
  cursor: pointer;
}

.main-photo {
  width: 50%;
  height: 100%;
  padding-right: 5px;
  background-size: cover;
  background-repeat: no-repeat;
}

.smaller-photos {
  width: 50%;
  height: 100%;
}
.smaller-photos .small-column {
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  margin-left: 3px;
  position: relative;
}

.more-photos {
  width: fit-content;
  position: absolute;
  bottom: 20px;
  right: 20px;
  border: 1.5px solid #000;
  background-color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 8pt;
  font-weight: bold;
}
.more-photos:hover {
  background-color: #efefef;
}
.more-photos:active {
  transform: scale(0.95);
}
.more-photos:focus {
  outline: none;
}

.list-info {
  width: 100%;
  padding: 30px 0;
  border-bottom: 1.5px solid #efefef;
  font-size: 13pt;
}

.list-info-left {
  width: 65%;
  padding-right: 50px;
}
.list-info-right {
  width: 35%;
  padding: 50px 0 0 25px;
}

.info-calendar {
  width: 100%;
  border: 1.5px solid #dddddd;
  padding: 20px 20px 10px 20px;
  border-radius: 15px;
  box-shadow: 4px 4px 20px #e6e6e6;
  font-size: 13pt;
}

.calendar-sticky {
  position: sticky;
  top: 50px;
  font-size: 12pt;
}
.info-calendar a {
  font-family: "Cereal";
}

.abnb-reserve-btn {
  width: 100%;
  padding: 15px;
  color: white;
  font-size: 12pt;
  font-family: "Cereal Header";
  text-align: center;
  border-radius: 7px;
  background-color: #f22d57;
  border: none;
  margin-top: 15px;
  transition: transform 0.1s;
}

.info-checkin {
  width: 100%;
  border: 1px solid #cacaca;
  border-radius: 7px;
  font-size: 10pt;
}

.info-price {
  font-size: 16pt;
  font-family: "Cereal Header";
}

.review-img {
  border-radius: 50px;
  width: 50px;
  height: 50px;
  background-repeat: no-repeat;
  background-size: cover;
}

.host-img {
  border-radius: 50px;
  width: 70px;
  height: 70px;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.super-host-icon {
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.abnb-button {
  border: 1.5px solid black;
  background-color: white;
  font-family: "Cereal Header";
  padding: 10px 15px;
  border-radius: 10px;
  transition: transform 0.1s;
}
.abnb-button:hover {
  background-color: #efefef;
}
.abnb-button:active {
  transform: scale(0.95);
}
.abnb-button:focus {
  outline: none;
}

.abnb-whitebox {
  padding: 25px;
  border: 1.5px solid #ddd;
  background-color: white;
  border-radius: 10px;
  font-size: 13pt;
}

.rating-light {
  width: 150px;
  height: 5px;
  background-color: #efefef;
  border-radius: 5px;
  overflow: hidden;
}

.rating-dark {
  background-color: #000;
  height: 100%;
  border-radius: 5px;
}

.main-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 15px 15px 15px 50px;
  border-top: 1.5px solid #cacaca;
  background-color: white;
  display: flex;
  font-size: 10pt;
  z-index: 100;
}

.carousel,
.carousel-item img {
  width: 300px;
  height: 285px;
  border-radius: 10px;
  object-fit: cover;
  position: relative;
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 45%;
  background-color: #fff;
  opacity: 75%;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  padding: 15px;
  font-size: 5pt;
  font-size: 12pt;
  border: 1px solid #cacaca;
  display: none;
}

.carousel-control-prev {
  left: 10px;
  color: black !important;
}
.carousel-control-next {
  right: 10px;
  color: black !important;
}
.carousel-control-prev:hover,
.carousel-control-next:hover {
  opacity: 100%;
}
.carousel:hover .carousel-control-next,
.carousel:hover .carousel-control-prev {
  display: flex;
}

.abnb-form {
  width: 100%;
  display: block;
}

.form-block {
  width: 50%;
  display: inline-block;
}

.form-title {
  width: 200px;
  padding: 15px;
  background-color: #efefef;
  font-weight: bold;
  font-size: 14pt;
}

.input-surround {
  margin-bottom: 40px;
  margin-right: 40px;
  border-radius: 15px;
  overflow: hidden;
  background-color: #efefef;
}

.form-input {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 15px;
  overflow: hidden;
  margin: 10px;
  padding: 10px;
}

.form-input input {
  width: 100%;
  outline: none;
  border: none;
}

.form-input textarea {
  width: 100%;
  height: 250px;
  outline: none;
  border: none;
  resize: none;
}

.form-input select {
  width: 100%;
  cursor: pointer;
  outline: none;
  border: none;
}
.dropdown-menu {
  width: 100%;
}

.loading-car-cont {
  width: 100%;
  height: 285px;
  background-color: #efefef;
  border-radius: 10px;
}

.loading-bar {
  background-color: #efefef;
  height: 25px;
  border-radius: 10px;
  width: 100%;
  margin-top: 10px;
}

.react-loading-skeleton {
  border-radius: 10px;
}

@media screen and (max-width: 1300px) {
  .abnb-1200 {
    width: 100%;
  }
  .abnb-list-container {
    width: 100%;
  }
  .abnb-list-main-cont {
    width: 100%;
    padding: 10px;
  }
}

@media screen and (max-width: 500px) {
  .logo {
    display: none !important;
  }
  .right {
    display: none !important;
  }
  .abnb-navbar {
    border: none;
  }
  .nav-right {
    display: none !important;
  }
  .abnb-search-input {
    width: 100%;
  }

  .abnb-icons {
    display: none;
  }
  .abnb-main-cont {
    margin-top: 25px;
  }
  .abnb-card {
    margin-right: 0;
    font-size: 11pt;
  }
  .main-footer {
    display: none;
  }

  .list-info-left {
    width: 100%;
    padding: 0;
  }
  .list-info-right {
    display: none;
    padding: 0;
  }
  .list-photos {
    height: 20vh;
    border-radius: 0px;
  }
  .main-photo {
    width: 100%;
  }
  .smaller-photos {
    display: none;
  }

  .leaflet-container {
    height: 300px;
  }
  .search-map-cont-wrapper {
    margin-top: 10px !important;
  }
  .search-map-container {
    width: 100%;
  }
  .search-results {
    display: none;
  }
}
